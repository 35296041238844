import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR, FormApi } from 'final-form';
import {
  LoginAlert,
  LoginTextField,
  Title,
} from '../../../components/molecules/Auth/components';
import { auth } from 'services/auth';
import ProgressButton from 'components/atoms/ProgressButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { MobilePhoneMask } from '../../profile/MobilePhoneMask';
import Rest from '../../../services/rest';
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil';
import { isPhoneNumberVerifiedSelector } from 'recoil/selectors';
import Alert from '../../../components/atoms/FormAlert';
import { useScreenSize } from 'hooks/useScreenSize';
import { SxProps } from '@mui/system';
import { styled, Theme as ThemeMui5 } from '@mui/material/styles';
import LinkMui5 from '../../../components/atoms/Link';
import { Timer } from '../../../components/atoms/Timer';
import { Body2LightTimerTextStyler } from 'components/atoms/TimerCommonStylers';
import {
  confirmationDialogOpenStateAtom,
  identityAtom,
  identityPhoneAtom,
} from 'recoil/atoms';
import { Identity } from 'shared/types/common';

const rest = new Rest();

interface ConfirmIdentityData {
  emailConfirmation?: string;
  newPhoneNumber?: string;
}

export interface IConfirmIdentityDialog {
  requestConfirmation(
    identity: Identity,
    options?: { forceSendConfimration?: boolean; openOnly?: boolean },
  ): Promise<boolean>;
}

export enum ViewMode {
  PHONE,
  EMAIL,
  PHONE_UPDATE,
}

export const DialogActionsContainer = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  justifyContent: 'flex-end',
}));

export const DialogActionsPhoneContainer = styled(DialogActions)(
  ({ theme }) => ({
    padding: theme.spacing(1, 0),
    justifyContent: 'flex-end',
  }),
);

const confirmButtonStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiButton-root': {
    margin: theme.spacing(0, 0, 0, 1),
  },
});

type ConfirmIdentityDialogProps = {
  onChangePhone?: (phone: string) => Promise<void>;
  title?: string;
  text?: string;
  secondaryText?: string;
  viewMode?: ViewMode;
};

let confirmAction;

const RESEND_CODE_DEADLINE_SEC = 180; // 3 mins

const ConfirmIdentityDialog: ForwardRefRenderFunction<
  IConfirmIdentityDialog,
  ConfirmIdentityDialogProps
> = (
  {
    title = 'Confirm phone number',
    text = 'Please enter the SMS code that was sent to',
    secondaryText = '',
    viewMode = ViewMode.PHONE,
    onChangePhone,
  },
  ref,
) => {
  const initialValues: ConfirmIdentityData = { emailConfirmation: '' };
  const [retrievedPhone, setPhone] = useRecoilState(identityPhoneAtom);
  const [isPhoneUpdating, setPhoneUpdating] = useState<boolean>(false);
  const [isEmailConfirmDisabled, setEmailConfirmDisabled] =
    useState<boolean>(false);
  const [updatePhoneError, setUpdatePhoneError] = useState<string>('');
  const [isOpened, toggleIsOpened] = useRecoilState(
    confirmationDialogOpenStateAtom,
  );
  const [identity, setIdentity] = useRecoilState(identityAtom);
  const isPhoneNumberVerifiedSelectorRefresh = useRecoilRefresher_UNSTABLE(
    isPhoneNumberVerifiedSelector,
  );
  const [isResendLoading, toggleIsResendLoading] = useState<boolean>(false);
  const [isPhoneNumberVisible, setPhoneNumberVisibility] =
    useState<boolean>(false);
  const [isResendCodeDisabled, setIsResendCodeDisabled] =
    useState<boolean>(isPhoneNumberVisible);
  const [resendCodeDeadline, setResendCodeDeadline] = useState<Date>(
    new Date(),
  );

  useImperativeHandle(ref, () => ({
    requestConfirmation,
  }));

  const setResendCodeTimer = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + RESEND_CODE_DEADLINE_SEC);
    setResendCodeDeadline(time);
    setIsResendCodeDisabled(true);
  };

  async function requestConfirmation(
    identity: Identity,
    { forceSendConfimration = false, openOnly = false } = {},
  ): Promise<boolean> {
    setIdentity(identity);
    toggleIsOpened(true);
    setResendCodeTimer();

    if (identity?.phone) {
      setPhone(identity.phone);
    }

    if (viewMode === ViewMode.PHONE || viewMode === ViewMode.PHONE_UPDATE) {
      const phone = await getPhone(identity);
      if (typeof phone === 'string') {
        setPhone(phone);
      }
    }

    if (forceSendConfimration) {
      await auth.resendConfirmationSms().catch((error) => {
        setUpdatePhoneError(error.message);
      });
    }

    return new Promise((resolve) => {
      confirmAction = (result: boolean) => {
        if (!openOnly) {
          toggleIsOpened(!result);
        }
        resolve(result);
      };
    });
  }

  const getPhone = async (identity: Identity) => {
    if (identity?.phone) {
      return identity.phone;
    }
    if (identity?.email && identity?.password) {
      return rest.getUserAttributeNonAuthenticated({
        email: identity.email,
        password: identity.password,
        attribute: 'phone_number',
      });
    }
    return '';
  };

  async function confirmEmail(data: ConfirmIdentityData) {
    try {
      if (!identity?.email) {
        return;
      }

      if (viewMode === ViewMode.PHONE_UPDATE) {
        await auth.confirmNewPhoneNumber(data.emailConfirmation);
        isPhoneNumberVerifiedSelectorRefresh();
      } else {
        setEmailConfirmDisabled(true);
        const confirmEmailResult = await auth.confirmEmail(
          identity.email,
          data.emailConfirmation,
        );
        if (confirmEmailResult === 'SUCCESS') {
          setEmailConfirmDisabled(false);
        }
      }

      if (confirmAction) {
        confirmAction(true);
      }
    } catch (error) {
      setEmailConfirmDisabled(false);
      return { [FORM_ERROR]: error.message };
    }
  }

  function resendConfirmation(email?: string) {
    if (viewMode === ViewMode.PHONE_UPDATE) {
      return auth.resendConfirmationSms();
    }
    return auth.resendConfirmationEmail(email);
  }

  function validate(values: ConfirmIdentityData) {
    const errors: ConfirmIdentityData = {};
    if (!values.emailConfirmation) {
      return { emailConfirmation: 'Required' };
    }
    if (isPhoneNumberVisible && !values.newPhoneNumber) {
      return { newPhoneNumber: 'Fill in a new phone number' };
    }
    return errors;
  }

  const toggleMobileNumberForm = (event?) => {
    event?.preventDefault();
    setPhoneNumberVisibility(!isPhoneNumberVisible);
  };

  const { isExtraSmallScreen } = useScreenSize();

  const handlePhoneNumberChange = async (
    form: FormApi<ConfirmIdentityData>,
  ) => {
    setPhoneUpdating(true);
    setUpdatePhoneError('');
    const newPhone = form.getState().values.newPhoneNumber;
    try {
      await onChangePhone?.(newPhone);
      setPhone(`+1${newPhone}`);
      toggleMobileNumberForm();
    } catch (e) {
      setUpdatePhoneError(e?.errors[0]?.msg);
    } finally {
      setPhoneUpdating(false);
    }
  };

  return (
    <Dialog
      open={isOpened}
      onClose={(_, reason: string) => {
        if (viewMode === ViewMode.EMAIL && reason === 'backdropClick') {
          return;
        }

        if (confirmAction) {
          confirmAction(false);
        }
      }}
      fullScreen={isExtraSmallScreen}
      disableEscapeKeyDown={viewMode === ViewMode.EMAIL}
    >
      <Form
        onSubmit={confirmEmail}
        initialValues={initialValues}
        validate={validate}
        render={({ form, handleSubmit, submitting, submitError }) => (
          <form autoComplete='off' onSubmit={handleSubmit} noValidate>
            <DialogContent>
              {submitError && (
                <LoginAlert severity='error' variant='standard'>
                  {submitError}
                </LoginAlert>
              )}
              {updatePhoneError && (
                <LoginAlert severity='error' variant='standard'>
                  {updatePhoneError}
                </LoginAlert>
              )}
              <Title variant='h5'>{title}</Title>
              <Typography variant='body1' gutterBottom>
                {text}
                {`: `}
                {viewMode === ViewMode.EMAIL && (
                  <span style={{ fontWeight: 'bold' }}>{identity?.email}</span>
                )}
                {(viewMode === ViewMode.PHONE ||
                  viewMode === ViewMode.PHONE_UPDATE) &&
                  retrievedPhone}
              </Typography>
              <LoginTextField
                name='emailConfirmation'
                label='Confirmation Code'
                autoComplete='password'
                disabled={isPhoneNumberVisible}
              />
              {secondaryText && (
                <Alert severity='info' variant='standard'>
                  {secondaryText}
                </Alert>
              )}
              <DialogActionsContainer>
                {viewMode === ViewMode.PHONE && (
                  <LinkMui5 onClick={toggleMobileNumberForm} href={'#'}>
                    Change mobile number
                  </LinkMui5>
                )}
                <div>
                  <ProgressButton
                    loading={isResendLoading}
                    onClick={async (e) => {
                      e.preventDefault();
                      toggleIsResendLoading(true);
                      try {
                        await resendConfirmation(identity?.email.trim());
                        setResendCodeTimer();
                      } catch (error) {
                        setUpdatePhoneError(error.message);
                      }
                      toggleIsResendLoading(false);
                    }}
                    variant='outlined'
                    disabled={isResendCodeDisabled}
                  >
                    Resend
                    {Boolean(resendCodeDeadline.getTime() - Date.now() > 0) && (
                      <>
                        &nbsp;
                        <Timer
                          endTime={resendCodeDeadline.toString()}
                          onTimeOut={() => {
                            setIsResendCodeDisabled(false);
                          }}
                          textType='minsec'
                          textTail=''
                        >
                          {Body2LightTimerTextStyler}
                        </Timer>
                      </>
                    )}
                  </ProgressButton>
                  <ProgressButton
                    color='primary'
                    type='submit'
                    onClick={handleSubmit}
                    loading={submitting || isEmailConfirmDisabled}
                    disabled={isPhoneNumberVisible || isEmailConfirmDisabled}
                    sx={confirmButtonStyles}
                  >
                    Confirm
                  </ProgressButton>
                </div>
              </DialogActionsContainer>

              {isPhoneNumberVisible && (
                <>
                  <LoginTextField
                    name='newPhoneNumber'
                    label='New Phone Number'
                    InputProps={{
                      inputComponent: MobilePhoneMask,
                    }}
                  />
                  <DialogActionsPhoneContainer>
                    <ProgressButton
                      color='primary'
                      onClick={async () => handlePhoneNumberChange(form)}
                      loading={isPhoneUpdating}
                    >
                      Update and get code
                    </ProgressButton>
                  </DialogActionsPhoneContainer>
                </>
              )}
            </DialogContent>
          </form>
        )}
      />
    </Dialog>
  );
};

export default forwardRef(ConfirmIdentityDialog);
